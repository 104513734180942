export const biColor = 1
export const biColorSpped = 2

export const normalTable = [
  {
    id: '25-49',
    min: 25,
    max: 49,
    price: 4.50,
  },
  {
    id: '50-99',
    min: 50,
    max: 99,
    price: 4.00,
  },
  {
    id: '100-149',
    min: 100,
    max: 149,
    price: 3.50,
  },
  {
    id: '150-200',
    min: 150,
    max: 200,
    price: 3.00,
  },
]

export const speedTable = [
  {
    id: '25-49',
    min: 25,
    max: 49,
    price: { unity: 4.50, forfait: 50 },
  },
  {
    id: '50-99',
    min: 50,
    max: 99,
    price: { unity: 4.00, forfait: 70 },
  },
  {
    id: '100-149',
    min: 100,
    max: 149,
    price: 4.00,
  },
  {
    id: '150-200',
    min: 150,
    max: 200,
    price: 3.50,
  },
]
