/* eslint-disable max-len */
import React from 'react'
import {
  ListContainer, IncludedList, ListLine,
} from '../Cabs/styles'

import { DonwloadPDF } from '../Tshirt/styles'

const List = () => (
  <ListContainer>
    <IncludedList>
      <ListLine>Les tarifs incluent le textile et sont valables pour des impressions de même taille et de même couleur. Les éventuels frais de port ne sont pas compris dans les tarifs.</ListLine>
      <ListLine>Tout besoin de modification de visuels par nos soins sera facturé 20€ minimum.</ListLine>
      <ListLine>Le délai moyen de fabrication est de 3 semaines à partir de la réception du paiement total. L’impression en 10 jours maximum est possible selon la quantité travail en cours et nos disponibilités. N’hésitez pas à nous contacter pour tout autre demande !</ListLine>
      <ListLine>
        <DonwloadPDF>Téléchargez les spécifications techniques</DonwloadPDF>
      </ListLine>
    </IncludedList>
  </ListContainer>
)

export default List
