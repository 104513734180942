import React from 'react'

import PricePreviw from '../Cabs/PricePreviw'
import Checkbox from '../Checkbox'
import List from './List'
import Grille from './Grille'
import Form, { Dialog, genDevis } from '../Form'
import PageImage from '../PageImage'
import StyledLin from '../StyledLink'
import Button from '../Button'
import { Footer } from '../Hardcase/styles'

import {
  Content,
  DevisTitle,
  TitleSection,
  Section,
  CheckBoxWrapper,
} from '../Cabs/styles'

import {
  Description, Subtitle, Input, InputLabel, InputContainer,
} from '../Tshirt/styles'

import { description, subTitle } from '../Tshirt/data'
import { validateQuantity } from '../Tshirt/helpers'

import { countTotal, genDataForEmailDevi } from './helpers'

class Bags extends React.Component {
  state = {
    quantity: '1',
    edited: false,
    speed: false,
    biColor: false,
    showForm: false,
  }

  onToggleForm = () => {
    const { showForm } = this.state
    this.setState({ showForm: !showForm })
  }

  onChangeQuantity = e => {
    const newQuantity = e.target.value
    if (newQuantity === '') {
      this.setState({ quantity: newQuantity, edited: true })
    } else {
      const cleanNumber = parseInt(newQuantity, 10)
      if (cleanNumber) {
        this.setState({ quantity: `${cleanNumber}`, edited: true })
      }
    }
  }

  onChangeSpeed = () => {
    const { speed } = this.state
    this.setState({ speed: !speed, edited: true })
  }

  onChangeBiColor = () => {
    const { biColor } = this.state
    this.setState({ biColor: !biColor, edited: true })
  }

  render() {
    const {
      quantity, edited, speed, biColor, showForm,
    } = this.state
    const amount = countTotal({ quantity, isBiColor: biColor, speed })

    const showPrice = amount
    const isValidQuantity = !edited || validateQuantity(quantity)
    const devisDataForMail = genDataForEmailDevi({
      quantity, isBiColor: biColor, speed,
    })
    const devisAsStringForMail = genDevis('Sacs', devisDataForMail, amount)

    return (
      <>
        {showPrice && (
        <PricePreviw amount={amount} text={[]} openForm={this.onToggleForm} />
        )}
        <Dialog show={showForm} onClose={this.onToggleForm}>
          <Form
            title="Envoyer le devis"
            emailSubject="Devis Prints"
            withDevis
            devis={devisAsStringForMail}
          />
        </Dialog>
        <PageImage page="bags" />
        <Content>
          {description.map((d, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Description key={i}>{d}</Description>
          ))}
          <DevisTitle>Créez votre devis</DevisTitle>
          <Subtitle>{subTitle}</Subtitle>
          <Section>
            <TitleSection>Nombre de sacs</TitleSection>
            <InputContainer>
              <InputLabel
                error={!isValidQuantity}
              >
                Quantité (entre 25 et 200 Sacs)
              </InputLabel>
              <Input
                error={!isValidQuantity}
                type="number"
                min="1"
                step="1"
                value={quantity}
                onChange={this.onChangeQuantity}
              />
            </InputContainer>
          </Section>
          <Section>
            <TitleSection>Options supplémentaires</TitleSection>
            <CheckBoxWrapper>
              <Checkbox
                // eslint-disable-next-line max-len
                label="Impression recto + verso ou une face 2 couleur (+1€/unité ou 2€/unité pour impression rapide)"
                checked={biColor}
                onChange={this.onChangeBiColor}
              />
            </CheckBoxWrapper>
            <CheckBoxWrapper>
              <Checkbox
                // eslint-disable-next-line max-len
                label="Impression rapide en 2 semaines (voir la grille tarifaire pour le détail)"
                checked={speed}
                onChange={this.onChangeSpeed}
              />
            </CheckBoxWrapper>
          </Section>
          <Section>
            <TitleSection>Informations supplémentaires</TitleSection>
            <List />
          </Section>
          <Section>
            <TitleSection>Grille tarifaire</TitleSection>
            <Grille />
          </Section>
          <Section>
            <TitleSection style={{ maxWidth: 300, margin: '0 auto' }}>
              {/* eslint-disable-next-line max-len */}
              Pour toute question, si vous souhaitez imprimer sur un autre support que ceux proposés et pour commander :
            </TitleSection>
            <Footer>
              <StyledLin to="/contact">
                <Button>Contactez-nous</Button>
              </StyledLin>
            </Footer>
          </Section>
        </Content>
      </>
    )
  }
}

export default Bags
