/* eslint-disable no-nested-ternary */
/* eslint-disable no-negated-condition */

import {
  normalTable, speedTable, biColor, biColorSpped,
} from './data'

const getPrice = ({
  table, quantity, isBiColor, speed, biColorPrice, biColorSppedPrice,
}) => {
  const range = table.find(({ min, max }) => quantity >= min && quantity <= max)

  if (!range) return null
  const unityPrice = typeof range.price === 'number'
    ? range.price
    : range.price.unity
  const forfaitPrice = typeof range.price === 'number'
    ? 0
    : range.price.forfait
  const biColorUnityPrice = !isBiColor
    ? 0
    : speed ? biColorSppedPrice : biColorPrice

  const price =
    (unityPrice * quantity) + forfaitPrice + (biColorUnityPrice * quantity)

  return price
}

export const countTotal = ({ quantity, isBiColor, speed }) => {
  const table = speed ? speedTable : normalTable

  return getPrice({
    table,
    quantity,
    isBiColor,
    speed,
    biColorPrice: biColor,
    biColorSppedPrice: biColorSpped,
  })
}

export const genDataForEmailDevi = ({
  quantity, isBiColor, speed,
}) => {
  const data = [
    {
      label: 'Quantité',
      value: quantity,
    },
    {
      label: 'Recto + verso 1 couleur ou une face 2 couleurs',
      value: isBiColor,
    },
    {
      label: 'Impression rapide en 2 semaines',
      value: speed,
    },
  ]

  return data
}
